import styles, { layout } from "../../styles/styles";
import Image from "next/image";

const Business = () => (
  <section className="relative py-10">
    <div className="container h-full mx-auto">
      <div className="flex flex-col lg:flex-row justify-between px-10">
        <div className="flex h-full w-full lg:w-1/3 flex-col items-center justify-center py-10 md:items-start md:py-20">
          <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-3xl dark:text-white md:text-left lg:text-3xl">
            A Simple Way of Funding new properties.
          </h1>
          <p className="dark:text-jacarta-200 mb-8 text-center text-base md:text-left">
            Discover a new way to invest in real state. <br />
            Invest in a part of a property and claim the corresponding dividends
            from the exploitation of all properties.
          </p>
        </div>

        <div className="flex flex-col justify-center mb-7 ">
          {" "}
          <div
            className={`flex flex-row p-6 rounded-[20px]  feature-card hover:dark:bg-jacarta-500 hover:bg-jacarta-200`}
          >
            <div
              className={`w-[60px] h-[60px] rounded-full ${styles.flexCenter} -mt-1`}
            >
              <div className="text-6xl">1.</div>
            </div>
            <div className="flex-1 flex flex-col ml-3 text-left">
              <h4 className="font-semibold text-jacarta-700 dark:text-white text-lg leading-[23.4px] mb-1">
                Fund Properties Buying PZL
              </h4>
              <p className="font-normal text-jacarta-600 dark:text-jacarta-300 leading-[24px] ">
                Buy some shares of the property using DAI
              </p>
            </div>
          </div>
          <div
            className={`flex flex-row p-6 rounded-[20px]  feature-card hover:dark:bg-jacarta-500 hover:bg-jacarta-200`}
          >
            <div
              className={`w-[60px] h-[60px] rounded-full ${styles.flexCenter} -mt-1`}
            >
              <div className="text-6xl">2.</div>
            </div>
            <div className="flex-1 flex flex-col ml-3 text-left">
              <h4 className="font-semibold text-jacarta-700 dark:text-white text-lg leading-[23.4px] mb-1">
                Claim Your PZL investment
              </h4>
              <p className="font-normal text-jacarta-600 dark:text-jacarta-300 leading-[24px]">
                Wait until the property succes to fund, or refund if fail to
                fund
              </p>
            </div>
          </div>
          <div
            className={`flex flex-row p-6 rounded-[20px]  feature-card hover:dark:bg-jacarta-500 hover:bg-jacarta-200`}
          >
            <div
              className={`w-[60px] h-[60px] rounded-full ${styles.flexCenter} -mt-1`}
            >
              <div className="text-6xl">3.</div>
            </div>
            <div className="flex-1 flex flex-col ml-3 text-left">
              <h4 className="font-semibold text-jacarta-700 dark:text-white text-lg leading-[23.4px] mb-1">
                Claim Dividends
              </h4>
              <p className="font-normal text-jacarta-600 dark:text-jacarta-300 leading-[24px]">
                Claim your dividends derived from the exploitation of all
                properties.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Business;
