import styles, { layout } from "../../styles/styles";
import Image from "next/image";

const Refund = () => (
  <section className="relative py-10">
    <div className="container mx-auto">
      <div className="flex flex-col lg:flex-row justify-between px-10">
        <div className="flex h-full w-full lg:w-1/2 flex-col  py-10 md:items-start md:py-20">
          <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-3xl dark:text-white md:text-left">
            Refund your PZL <br /> if a propoerty failed to fund
          </h1>
          <p className="dark:text-jacarta-200 text-jacarta-700 mb-8 text-center text-base md:text-left">
            Certainly, if a property is not fully financed by the end of the
            financing period, investors will be able to recover their investment
            from the smart contract, with only one click!.
          </p>
        </div>

        <div className="flex flex-col mx-10 items-center  h-[350px] transition-transform duration-300 transform-gpu hover:scale-105">
          <Image
            src={"/images/refundArrow.png"}
            height={866 * 0.7}
            width={666 * 0.7}
            className="object-contain"
            alt="refund_arrow"
          ></Image>
        </div>
      </div>
    </div>
  </section>
);

export default Refund;
