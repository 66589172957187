import Image from "next/image";

const InterfaceCard = () => (
  <section className="relative pb-10">
    <div className="container mx-auto">
      <div className="flex flex-col lg:flex-row-reverse justify-between px-10">
        <div className="flex h-full w-full lg:w-1/2 flex-col items-center justify-center py-10 md:items-start md:mt-24">
          <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-3xl dark:text-white md:text-left">
            Fast and Simple Interface
          </h1>
          <p className="dark:text-jacarta-200 text-jacarta-700 mb-8 text-center text-base md:text-left">
            Effortless Navigation for Seamless Experiences <br /> Discover a
            user-friendly interface designed to streamline your interactions.
            Navigate with ease and enjoy a fluid browsing journey, making every
            interaction a breeze. Experience simplicity at its finest,
            empowering you to focus on what truly matters.
          </p>
        </div>

        <div className="flex flex-col justify-center opacity-90 dark:opacity-100 transition-transform duration-300 transform-gpu hover:scale-105">
          <Image
            src={"/images/interface_card_mix.png"}
            height={400}
            width={400}
            className="object-contain"
            alt="interface_card"
          />
        </div>
      </div>
    </div>
  </section>
);

export default InterfaceCard;
