import React, { useEffect, useState } from "react";
import Link from "next/link";

import { plzTotalSupply } from "../metamask/Metamask";
import { formatNumber } from "../../utils/invoiceDataNormalizer";
import {
  useLazyGetBaseContractsQuery,
  useLazyGetProtocolStatsQuery,
  useLazyGetDividendDropsQuery,
} from "../../redux/features/mainApiSlice";

function ProtocolStats() {
  const [getBaseContracts, { isFetching: isFetchingContracts }] =
    useLazyGetBaseContractsQuery();
  const [getDividendsDrop, { isFetching: isFetchingDividendDrops }] =
    useLazyGetDividendDropsQuery();
  const [getProtocolStats, { isFetching: isFetchingProtocolStats }] =
    useLazyGetProtocolStatsQuery();

  const [totalOWNDividends, setTotalOWNDividends] = useState(0);
  const [plzMinted, setPlzMinted] = useState(0);
  const [protocolStats, setProtocolStats] = useState(0);
  const [pzlAssetRatio, setPzlAssetRatio] = useState(0);
  const [dividendsDropped, setDividendDropped] = useState(0);

  function sumAmountsInContract(dataArray) {
    // Inicializa una variable para almacenar la suma
    let totalAmount = 0;

    // Itera a través de cada objeto en el array
    dataArray.forEach((item) => {
      // Convierte el valor de amount_in_contract a un número flotante y lo suma al total
      totalAmount += parseFloat(item.amount_in_contract);
    });

    // Devuelve la suma total
    return totalAmount;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseContracts = await getBaseContracts().unwrap();

        const plzTotalSupplyResponse = await plzTotalSupply(
          baseContracts.data.pzl_address
        );
        setPlzMinted(plzTotalSupplyResponse);

        const protocolStatsResponse = await getProtocolStats().unwrap();
        setProtocolStats(protocolStatsResponse.data);
        const ratio =
          protocolStatsResponse.data.amount_all_funded /
          (plzTotalSupplyResponse / 10 ** 18);
        setPzlAssetRatio(ratio);

        const dividendDrops = await getDividendsDrop().unwrap();
        setDividendDropped(sumAmountsInContract(dividendDrops.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 gap-y-10 p-5 sm:scale-90 md:scale-100">
      <div className="flex flex-col justify-center items-center sm:-mb-10">
        <p className="text-5xl text-accent-dark text-center">
          {protocolStats.active_properties}
        </p>
        <p className="text-lg font-display dark:text-white text-jacarta-500">
          Active Properties
        </p>
      </div>

      <div className="flex flex-col justify-center items-center sm:-mb-10">
        <p className="text-5xl text-blue">
          {formatNumber(parseInt(plzMinted / 10 ** 18))}{" "}
          <span className="text-sm text-blue -ml-2">PZL</span>
        </p>
        <p className="text-lg font-display dark:text-white text-jacarta-500">
          PLZ Minted
        </p>
      </div>
      <div className="flex flex-col justify-center items-center sm:-mb-10">
        <p className="text-5xl text-orange">
          {pzlAssetRatio.toFixed(2)}
          <span className="text-sm text-orange ml-1">DAI/PZL</span>
        </p>
        <p className="text-lg font-display dark:text-white text-jacarta-500">
          Asset/PZL Ratio
        </p>
      </div>

      <div className="flex flex-col justify-center items-center sm:-mb-10">
        <p className="text-5xl text-green">
          {dividendsDropped && formatNumber(dividendsDropped.toFixed(2))}
          <span className="text-sm text-green ml-1">USDT</span>
        </p>
        <p className="text-lg font-display dark:text-white text-jacarta-500">
          Dividends Distributed
        </p>
      </div>
    </div>
  );
}

export default ProtocolStats;
