"use client";
import Image from "next/image";
import { NoSsr } from "@mui/material";

const Team = () => {
  return (
    <NoSsr>
      <div className="my-20 dark:bg-jacarta-800 bg-white py-16">
        <h1 className="text-jacarta-700 font-bold font-display text-3xl dark:text-white text-center">
          Meet the Team
        </h1>
        <p className="text-center mb-16 dark:text-jacarta-200 text-jacarta-700">
          Passionate team dedicated to delivering exceptional solutions.
        </p>
        <div className="flex gap-20 mx-auto justify-center lg:flex-row flex-col items-center">
          <div className="lg:w-1/4 w-1/2 hover:shadow-lg rounded-lg p-5">
            <div className="flex justify-center mb-5">
              <Image
                height={80}
                width={80}
                className="object-cover rounded-full shadow"
                src="/images/gut.jpg"
                alt="Person"
              />
            </div>
            <div className="flex flex-col justify-center items-center mt-2">
              <p className="text-lg font-bold">Agustín González Ribas</p>
              <p className="mb-4 text-xs text-gray-800">CEO</p>
              <div className="text-sm tracking-wide text-gray-800">
                <p className="text-justify dark:text-jacarta-200 text-jacarta-700">
                  I am an experienced user proficient in creating smart
                  contracts and handling the graphical user interface for
                  backend maintenance. I take pride in overseeing the
                  technological implementation to ensure a seamless user
                  experience. <br /> And LOST is the best TV show!
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/4 w-1/2  hover:shadow-lg rounded-lg p-5 hidden">
            <Image
              height={80}
              width={80}
              className="object-cover rounded-full shadow"
              src="/images/angel.jpg"
              alt="Person"
            />
            <div className="flex flex-col justify-center mt-2">
              <p className="text-lg font-bold">Angel Matabosch Serrat</p>
              <p className="mb-4 text-xs text-gray-800">Asset Cheif Managger</p>
              <p className="text-sm tracking-wide dark:text-jacarta-200 text-jacarta-700">
                With extensive real estate market knowledge, Angel excels in
                property evaluation, market analysis, and negotiation. Strong
                communication fosters client relationships, ensuring successful
                transactions. Stay updated on trends, regulations, and
                investment opportunities for real estate expertise.
              </p>
            </div>
          </div>
        </div>
      </div>
    </NoSsr>
  );
};

export default Team;
