import React from "react";
import Link from "next/link";
import Image from "next/image";

function FindInGoogleMaps({ coordinates }) {
  return (
    <div className="flex transform transition-transform hover:scale-110">
      <figure className="mr-2 ">
        <Link href={`https://www.google.com/maps?q=${coordinates}`}>
          <a className="relative block">
            <Image
              src="/images/map_icon.png"
              alt="google_maps"
              className="rounded-2lg"
              loading="lazy"
              width={30}
              height={30}
            />
          </a>
        </Link>
      </figure>

      <Link href={`https://www.google.com/maps?q=${coordinates}`}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-accent block mt-2"
        >
          <span className="text-sm ">Find in GoogleMaps</span>
        </a>
      </Link>
    </div>
  );
}

export default FindInGoogleMaps;
