import React from "react";
import { Hero } from "../../components/component";
import Meta from "../../components/Meta";
import ProtocolStats from "../../components/home/ProtocolStats";
import Business from "../../components/home/Business";
import InterfaceCard from "../../components/home/InterfaceCard";
import Team from "../../components/home/Team";
import Refund from "../../components/home/Refund";
import FirstRound from "../../components/home/FirstRound";
import NewProperty from "../../components/home/NewProperty";

const Home_1 = () => {
  return (
    <main>
      <Meta title="" />
      <Hero />
      <ProtocolStats />
      <NewProperty />
      <FirstRound />
      <Business />
      <InterfaceCard />
      <Refund />
      <Team />
    </main>
  );
};

export default Home_1;
