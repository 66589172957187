import Image from "next/image";
import Link from "next/link";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Items_Countdown_timer from "../items_countdown_timer";
import formatter from "../formatter";
import { useEffect, useState } from "react";
import FindInGoogleMaps from "../FindInGoogleMaps";
import { useLazyRetrieveNewPropertiesQuery } from "../../redux/features/mainApiSlice";
import { backendUrl } from "../../redux/backendConfig";
import { dateToTimestamp } from "../../utils/dateToTimestamp";

const NewProperty = () => {
  const [countDown, setCountDown] = useState(0);
  const [getNewProperty, { isFetching }] = useLazyRetrieveNewPropertiesQuery();
  const [nextProperty, setNextProperty] = useState({});

  useEffect(() => {
    getNewProperty()
      .unwrap()
      .then((response) => {
        setNextProperty(response.data);
        setCountDown(dateToTimestamp(response.data.launch_at));
      });
  }, []);

  return (
    <section className="relative pt-20">
      <div className="container mx-auto">
        <div className=" flex flex-col lg:flex-row justify-between pt-10 pl-10">
          <div className="flex h-full w-full lg:w-1/2  flex-col items-center justify-end py-10 md:items-start md:py-0 mt-5">
            <h1 className="relative text-jacarta-700 font-bold font-display mb-4 text-center text-3xl dark:text-white md:text-left">
              <div className=" sm:hidden">
                <Image
                  src={"/images/coming_soon.png"}
                  height={150}
                  width={150}
                  className=" object-contain"
                  alt="comming_soon"
                ></Image>
              </div>
              New Property{" "}
              <span>
                {Date.now() / 1000 < countDown ? "incoming!!" : "Added!!"}
              </span>
              <div className="absolute -top-20 -right-28 -z-10 hidden sm:block">
                <Image
                  src={"/images/coming_soon.png"}
                  height={150}
                  width={150}
                  className=" object-contain"
                  alt="comming_soon"
                ></Image>
              </div>
            </h1>
            <div className="flex gap-10">
              {Date.now() / 1000 > nextProperty.launch_at && (
                <div className="-mt-2 w-1/2">
                  <Link href={"/collection/explore_collection"} className>
                    <button className="font-display bg-accent rounded-lg hover:bg-accent-dark px-4 py-3 text-white mb-2">
                      Buy&nbsp;This
                    </button>
                  </Link>
                </div>
              )}

              <div className="-mt-5 opacity-70 scale-90 mb-4 flex">
                {countDown && (
                  <Items_Countdown_timer
                    time={(countDown - Date.now() / 1000) * 1000}
                  />
                )}
              </div>
            </div>
            <div className="font-display text-xl mb-3 mt-2">
              {nextProperty.title}{" "}
              <span className="text-green ml-3">
                {formatter.format(nextProperty.price)}
              </span>
            </div>

            <div className="mb-4 ml-4">
              <ul className="list-disc">
                <li className="flex">
                  Address:{" "}
                  <span className="font-bold ml-2">{nextProperty.address}</span>
                  <div className="ml-4">
                    <FindInGoogleMaps coordinates={nextProperty.geo_coords} />
                  </div>
                </li>
                <li>
                  Area:{" "}
                  <span className="font-bold">
                    {nextProperty.square_meters} m2
                  </span>
                </li>
                <li>
                  Rooms:
                  <span className="font-bold"> {nextProperty.bedrooms}</span>
                </li>
                <li>
                  State: <span className="font-bold">{nextProperty.city}</span>
                </li>
                <li>
                  Country:{" "}
                  <span className="font-bold">{nextProperty.country}</span>
                </li>
                <li>
                  <div className="flex">
                    <div>Estimated month income: </div>

                    <div className="font-bold text-green ml-2 relative">
                      {`$${parseInt(nextProperty.avg_rent_price)}~$${parseInt(
                        nextProperty.avg_rent_price * 1.5
                      )}`}
                      <div className=" absolute -top-3 -right-10">
                        <Image
                          src="/images/airbnb.png"
                          width={50}
                          height={50}
                          alt="airbnb"
                        />
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  Elevator:{" "}
                  <span className="font-bold">
                    {nextProperty.has_elevator ? "yes" : "no"}
                  </span>
                </li>
              </ul>
            </div>
            <p>{nextProperty.description}</p>
          </div>
          <div className="flex  justify-center object-fill lg:w-1/2">
            <figure className=" z-10 mb-8 p-12 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-full w-full lg:px-24 lg:py-10">
              <div className=" border-2 dark:border-jacarta-200 border-jacarta-500  rounded-xl scale-125">
                {nextProperty.images && (
                  <Carousel showThumbs={false} showStatus={false}>
                    {nextProperty.images.map((image) => (
                      <div key={image.id}>
                        <img
                          src={`${backendUrl}/api${image.image}`}
                          alt={`item ${image.id}`}
                          className="h-full w-full rounded-[0.625rem] object-contain"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewProperty;
