import Image from "next/image";
import Link from "next/link";

const FirstRound = () => (
  <section className="relative pt-20">
    <div className="container mx-auto">
      <div className="relative flex flex-col lg:flex-row-reverse justify-between pt-10 px-10">
        <div className="md:absolute flex justify-center lg:top-10 lg:right-10 md:right-20 md:top-64 ">
          <Image src={"/images/new.png"} height={150} width={150} alt="new" />
        </div>
        <div className="flex h-full w-full lg:w-1/2 lg:mt-16 flex-col items-center justify-end py-10 md:items-start md:py-20">
          <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-3xl dark:text-white md:text-left">
            First Of Two Funding Rounds
          </h1>
          <p className="dark:text-jacarta-200 text-jacarta-700 mb-8 text-center text-base md:text-left">
            Puzzle-Housing Offers 5% Of The Shares of Puzzle-Housing For 30,000
            DAI. Puzzle-Housing Will Offer To The Public 30,000 Conmpany Shares
            Tokens In Exchange 1:1 DAI. Also Puzzle Housing Will Mint 5% More
            Tokenes (30,000) To Provide Liquidity On Pools And As Reward For
            Promoting The Platform
          </p>
          <Link href={"/puzzleHousingICO"}>
            <button className="font-display bg-accent rounded-lg hover:bg-accent-dark p-4 text-white">
              Learn More
            </button>
          </Link>
        </div>

        <div className="flex flex-col justify-center object-fill">
          <Image
            src={"/images/first_round_interior.png"}
            height={529}
            width={464}
            className="object-contain"
            alt="interior"
          />
        </div>
      </div>
    </div>
  </section>
);

export default FirstRound;
